//
//  src/components/footer/Footer.js
//
import React, { useState, useEffect } from "react";
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAt
} from '@fortawesome/free-solid-svg-icons'
import { 
    faFacebook,
    faInstagram
} from '@fortawesome/free-brands-svg-icons'


//
//  footer [social-icon]
//
const FooterSocialIcon = (props) => (
    <div className="social">
        <a href={props.url}>
            <FontAwesomeIcon className="fa" icon={ props.icon } />
        </a>
    </div>
)


//
//  footer [desktop]
//
const FooterDesktop = (props) => (
    <>
        <footer className="desktop white">
            <div className="row">
                <div className="col s4 location">
                    <div className="col s12">
                        <div>Located</div>
                        <div>Melbourne Compendium</div>
                        <div>Melbourne, Australia</div>
                    </div>
                </div>
                <div className="col s4">
                    <div className="row socials">
                        <FooterSocialIcon icon={faAt}        url="/contact/"   />                        
                        <FooterSocialIcon icon={faFacebook}  url="https://www.facebook.com/JeffTattooer/"   />
                        <FooterSocialIcon icon={faInstagram} url="https://www.instagram.com/jeff_tattooer/" />
                    </div>
                </div>                
                <div className="col s4 copy">
                    <span>Terms <span>&</span> Conditions</span>
                    <br />
                    <span>Jeff Tattooer <span>&copy;</span> Jeffry Lau</span>
                    <br />
                    <span>2016 - { new Date().getFullYear() }</span>
                </div>
            </div>
        </footer>
    </>
)


//
//  footer [tablet]
//
const FooterTablet = (props) => (
    <footer className="mobile white">
        <div className="row socials">
            <FooterSocialIcon icon="facebook"  url="https://www.facebook.com/JeffTattooer/"   />
            <FooterSocialIcon icon="instagram" url="https://www.instagram.com/jeff_tattooer/" />
        </div>
        <div className="row location">
            <div>Located</div>
            <div className="col s12">
                Melbourne Compendium
            </div>
            <div className="col s12">Melbourne, Australia</div>
        </div>
        <div className="row copy">
            <br />
            <span>Jeff Tattooer <span>&copy;</span> Jeffry Lau</span>
            <span>2016 - { new Date().getFullYear() }</span>
        </div>
    </footer>
)


//
//  footer [mobile]
//
const FooterMobile = (props) => (
    <footer className="mobile white">
        <div className="row socials">
            <FooterSocialIcon icon="facebook"  url="https://www.facebook.com/JeffTattooer/"   />
            <FooterSocialIcon icon="instagram" url="https://www.instagram.com/jeff_tattooer/" />
        </div>
        <div className="row location">
            <div>Located</div>
            <div className="col s12">
                Melbourne Compendium
            </div>
            <div className="col s12">Melbourne, Australia</div>
        </div>
        <div className="col s12 copy">
            <span>Jeff Tattooer <span>&copy;</span> Jeffry Lau</span>
            <div>2016 - { new Date().getFullYear() }</div>
        </div>
    </footer>
)


//
//  footer [responsive]
//
export default function Footer() {

    // viewport[s]
    const [isDesktop, setDesktop] = useState( window.innerWidth  >  1000 );
    const [isTablet,  setTablet]  = useState( window.innerWidth === 1000 );
    const [isMobile,  setMobile]  = useState( window.innerWidth  <  1000 );

    // update media viewport
    const updateMedia = () => {
        setDesktop( window.innerWidth  >  1000 );
        setTablet(  window.innerWidth === 1000 );
        setMobile(  window.innerWidth  <  1000 );
    };

    // use resize
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    // return
    return (
        <>
            { isDesktop && <FooterDesktop /> }
            { isTablet  && <FooterTablet  /> }
            { isMobile  && <FooterMobile  /> }
        </>
    );

}
