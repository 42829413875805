//
//  src/components/wall/Wall.js
//
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay  from 'react-awesome-slider/dist/autoplay';
import React         from "react";
import { Link }      from "react-router-dom";
import 'react-awesome-slider/dist/styles.css';
import './Slider.css';


//
//  ink[s].
//
import Ink1 from "../../images/sliders/2/1.webp"
import Ink2 from "../../images/sliders/2/2.webp"
import Ink3 from "../../images/sliders/2/3.webp"
import Ink4 from "../../images/sliders/2/4.webp"
import Ink5 from "../../images/sliders/2/5.webp"
import Ink6 from "../../images/sliders/2/6.webp"


//
//  slider.
//
const AutoplaySlider = withAutoplay(AwesomeSlider);
const AutoplaySlider1 = (
    <AutoplaySlider
        bullets={ false }
        organicArrows={ false }
        play={ true }
        interval={ 4000 }
    >
        <div><img src={Ink1} alt="" /></div>
        <div><img src={Ink2} alt="" /></div>
        <div><img src={Ink3} alt="" /></div>
        <div><img src={Ink4} alt="" /></div>
        <div><img src={Ink5} alt="" /></div>
        <div><img src={Ink6} alt="" /></div>
    </AutoplaySlider>
)


//
//  wall.
//
export default function Landing() {

    // return.
    return (
        <>
            <Link to={ "/gallery/"  }>
                {AutoplaySlider1}
            </Link>
        </>
    );

}
