//
//  src/components/enquire/EnquireSent.js
//
import * as React from "react";
import './Enquire.css';



//
//  enquire sent
//
export default function EnquireSent(props) {

    return (
        <div className="enquire enquire-message enquire-sent valign-wrapper">
            <div className="container">
                <h1>Thank You</h1>
                <h4>Message Received</h4>
                <br />
                <p>
                    A confirmation has been sent to your email.<br />
                    If you did not receive a message please contact me directly at <a href="mailto:jeff@jefftattooer.com">jeff@jefftattooer.com</a>
                </p>
                <br /><br />
            </div>
        </div>
    )
    
}

