//
//  src/layouts/depict/works/Works.js
//
import * as React from "react";
import Nav    from "../../../components/nav/Nav"
import Wall   from "../../../components/wall/Wall"
import Footer from "../../../components/footer/Footer"


//
//  works.
//
export default function Works() {
    return (
        <div id="works">
           <Nav    />
           <Wall   />
           <Footer />
        </div>
    );
}
