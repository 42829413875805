//
//  src/components/enquire/EnquireForm.js
//
import EnquireReferrer                from './EnquireReferrer';
import React, { useEffect, useState } from "react";
import { useNavigate }                from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";
import './Enquire.css';


//
//  enquire form
//
export default function EnquireForm(props) {

    const navigate = useNavigate();

    const[data, setData] = useState({
        'from_name'       : "",
        'from_name_first' : "",
        'from_name_last'  : "",
        'from_email'      : "",
        'message'         : "",
        'referrer_code'   : "",
        'type'            : "form.contact"            
    });

    const [isDisabled, setDisabled] = useState(true);
    const [isReferred, setReferred] = useState(true);

    const referrers  = ["L337HACKER"];

    const send = (e) => {

        // disabled
        setDisabled(true)

        // validate
        if( ! validate(data['from_email']) ){
            return false;
        }

        // form options
        let options = {
            method  : 'POST',
            headers : { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }

        // send.
        fetch(process.env.REACT_APP_API_BASE + '/contact', options)
        .then(response => response.json())
        .then(data => sendSuccess(data))
        .catch((error) => { sendError(error) });

    }

    const sendError = (error) => {
        console.error(error)
        setData(data);
        setDisabled(true)
        navigate("/contact/error/");
    }

    const sendSuccess = (data) => {
        data = {}
        setData(data);
        setDisabled(false)
        navigate("/contact/sent/");
    }

    const set = (key, value) => {

        // set data[key] value
        data[key] = value

        // set data[from_name]
        if( data['from_name_first'] === undefined ){
            data['from_name_first'] = ""
        }
        if( data['from_name_last'] === undefined ){
            data['from_name_last'] = ""
        }        
        data['from_name'] = data['from_name_first'] + " " + data['from_name_last']

        // set message[referrer]
        if( data['message'] ){
            let referrer_code = referrers.find(item => data['message'].toLocaleLowerCase().includes(item.toLowerCase()));
            if( referrer_code ){
                data['referrer_code'] = referrer_code;
                setReferred(true);
            } else {
                data['referrer_code'] = '';
                setReferred(false);
            }
        } else {
            data['referrer_code'] = '';
            setReferred(false);            
        }

        // validate
        if( validate(data['from_email']) ){
            setDisabled(false)
        }

        // set data
        setData(data);

    }

    const submit = (event) => {
        event.preventDefault();
    };

    const validate = (email) => {
        return email.match(
          // eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    useEffect(() => {

        // materialize
        M.updateTextFields()

    });

    return (
        <div className="enquire enquire-form valign-wrapper">
            <div className="container">
                <div className="row">
                    <form className="col s12" onSubmit={submit}>
                        <div className="row">
                            <div className="center-align">
                                <br />
                                <h4><b>Contact our team</b></h4>
                                <p>Our team is happy to answer your questions.<br />
                                Fill out the form below and we'll be in touch.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="first_name"  onChange={(e) => {set('from_name_first', e.target.value)}} placeholder="First Name" type="text" className="validate" />
                                <label htmlFor="first_name">First Name</label>
                            </div>
                            <div className="input-field col s6">
                                <input id="last_name"  onChange={(e) => {set('from_name_last', e.target.value)}}  placeholder="Last Name" type="text" className="validate" />
                                <label htmlFor="last_name">Last Name</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="email" onChange={(e) => {set('from_email', e.target.value)}} placeholder="E-Mail" type="email" className="validate" required />
                                <label htmlFor="email">Email</label>
                            </div>
                        </div>                
                        {isReferred && data['referrer_code'] !== "" ? (
                            <EnquireReferrer code={ data['referrer_code'] } />
                        ) : ( 
                            <></> 
                        )}                        
                        <div className="row">
                            <div className="input-field col s12">
                                <textarea id="message" onChange={(e) => {set('message', e.target.value)}} placeholder="Message" className="materialize-textarea">
                                </textarea>
                                <label htmlFor="message">Message</label>
                            </div>
                        </div>
                        <div className="row">
                            <button id="submit" className="btn waves-effect waves-light orange" onClick={send} name="action" disabled={isDisabled} >
                                Send Message
                            </button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    );

}
