//
//  src/components/enquire/EnquireReferrer.js
//
import React, {} from "react";
import './EnquireReferrer.css';


//
//  enquire referrer
//
export default function EnquireReferrer(props) {


    return (
        <div className="referrer animate">
            <span>{ props.code }</span>
        </div>
    );

}
