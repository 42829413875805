//
//  src/components/sliders/Slider0.js
//
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay  from 'react-awesome-slider/dist/autoplay';
import React         from "react";
import { Link }      from "react-router-dom";
import 'react-awesome-slider/dist/styles.css';
import './Slider.css';


//
//  ink[s]
//
import Ink1  from "../../images/sliders/1/1.webp"
import Ink2  from "../../images/sliders/1/2.webp"
import Ink3  from "../../images/sliders/1/3.webp"
import Ink4  from "../../images/sliders/1/4.webp"
import Ink5  from "../../images/sliders/1/5.webp"
import Ink6  from "../../images/sliders/1/6.webp"
import Ink7  from "../../images/sliders/2/1.webp"
import Ink8  from "../../images/sliders/2/2.webp"
import Ink9  from "../../images/sliders/2/3.webp"
import Ink10 from "../../images/sliders/2/4.webp"
import Ink11 from "../../images/sliders/2/5.webp"
import Ink12 from "../../images/sliders/2/6.webp"


//
//  slider
//
const AutoplaySlider = withAutoplay(AwesomeSlider);
const AutoplaySlider0 = (
    <AutoplaySlider
        bullets={ false }
        organicArrows={ false }
        play={ true }
        interval={ 4000 }
    >
        <div><img src={Ink1}  alt="arm fighter tiger hat sword warrior" /></div>
        <div><img src={Ink2}  alt="arm colorful nature dragon" /></div>
        <div><img src={Ink3}  alt="arm colorful orc woman flowers" /></div>
        <div><img src={Ink4}  alt="arm greyscale lion deep" /></div>
        <div><img src={Ink5}  alt="arm sea octopus battle" /></div>
        <div><img src={Ink6}  alt="arm helmet soldier creature" /></div>
        <div><img src={Ink7}  alt="arm fighter tiger hat sword warrior" /></div>
        <div><img src={Ink8}  alt="arm colorful nature dragon" /></div>
        <div><img src={Ink9}  alt="arm colorful orc woman flowers" /></div>
        <div><img src={Ink10} alt="arm greyscale lion deep" /></div>
        <div><img src={Ink11} alt="arm sea octopus battle" /></div>
        <div><img src={Ink12} alt="arm helmet soldier creature" /></div>        
    </AutoplaySlider>
)


//
//  slider
//
export default function Slider() {

    // return.
    return (
        <>
            <Link to={ "/gallery/"  }>
                {AutoplaySlider0}
            </Link>
        </>
    );

}
