//
//  src/components/nav/Nav.js
//
import React, { useState, useEffect } from "react";
import { Link }                       from "react-router-dom";
import Loading                        from "../loading/Loading.js"
import logo                           from '../../images/logo.webp';
import "materialize-css/dist/css/materialize.min.css";
import './Nav.css'


//
//  nav [desktop].
//
const NavDesktop = (props) => (
    <>
        <nav className="desktop white">
            <Loading />
            <div className="nav-wrapper">
                <div className="brand-logo left">
                    <Link to={ "/" }>
                        <img src={logo} alt="Logo" />
                    </Link>
                </div>
                <div className="center">
                    <ul id="nav-mobile">
                        <li>
                            <Link className="black-text" to={ "/" }>
                                <h6 className="black-text center-align">Jeff Tattooer</h6>
                            </Link>
                        </li>
                        <li><Link className="black-text" to={ "/contact/"  }>contact</Link></li>
                        <li><Link className="black-text" to={ "/gallery/"  }>gallery</Link></li>
                        <li><Link className="black-text" to={ "/location/" }>location</Link></li>
                        <li><Link className="black-text" to={ "https://shop.jefftattooer.com" }>shop</Link></li>
                        <li className="right">
                            <Link className="black-text" to={ "/contact/" }>
                                <div className="booking">
                                    <b>book now</b>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
)


//
//  nav [mobile].
//
const NavMobile = (props) => (
    <nav className="mobile white">
        <Loading />
        <div className="nav-wrapper">
            <div className="row brand">
                <div className="brand-logo center">
                    <Link to={ "/" }>
                        <img src={logo} alt="Logo" />
                    </Link>
                </div>
            </div>
            <div className="row title">
                <Link className="black-text" to={ "/" }>
                    <h6 className="black-text center-align">Jeff Tattooer</h6>
                </Link>
            </div>
            <div className="row links">
                <ul>
                    <li><Link className="black-text" to={ "/contact/"  }>contact</Link></li>
                    <li><Link className="black-text" to={ "/gallery/"  }>gallery</Link></li>
                    <li><Link className="black-text" to={ "/location/" }>location</Link></li>
                    <li><Link className="black-text" to={ "https://shop.jefftattooer.com" }>shop</Link></li>
                </ul>
            </div>
        </div>
    </nav>
)


//
//  nav [responsive].
//
export default function Nav() {

    // viewport[s].
    const [isDesktop, setDesktop] = useState( window.innerWidth  >  1000 );
    const [isMobile,  setMobile]  = useState( window.innerWidth  <  1000 );

    // update media viewport.
    const updateMedia = () => {
        setDesktop( window.innerWidth > 1000 );
        setMobile(  window.innerWidth < 1000 );
    };

    // use resize.
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        setTimeout(function() {
            updateMedia();
        }, 200)
        return () => window.addEventListener("resize", updateMedia);
    });

    // return.
    return (
        <>
            { isDesktop && <NavDesktop /> }
            { isMobile  && <NavMobile  /> }
        </>
    );

}
