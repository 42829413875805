//
//  src/components/wall/Wall.js
//
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay  from 'react-awesome-slider/dist/autoplay';
import React         from "react";
import { Link }      from "react-router-dom";
import 'react-awesome-slider/dist/styles.css';
import './Slider.css';


//
//  ink[s].
//
import Ink1 from "../../images/sliders/1/1.webp"
import Ink2 from "../../images/sliders/1/2.webp"
import Ink3 from "../../images/sliders/1/3.webp"
import Ink4 from "../../images/sliders/1/4.webp"
import Ink5 from "../../images/sliders/1/5.webp"
import Ink6 from "../../images/sliders/1/6.webp"


//
//  slider.
//
const AutoplaySlider = withAutoplay(AwesomeSlider);
const AutoplaySlider1 = (
    <AutoplaySlider
        bullets={ false }
        organicArrows={ false }
        play={ true }
        interval={ 4000 }
    >
        <div><img src={Ink1} alt="arm fighter tiger hat sword warrior" /></div>
        <div><img src={Ink2} alt="arm colorful nature dragon" /></div>
        <div><img src={Ink3} alt="arm colorful orc woman flowers" /></div>
        <div><img src={Ink4} alt="arm greyscale lion deep" /></div>
        <div><img src={Ink5} alt="arm sea octopus battle" /></div>
        <div><img src={Ink6} alt="arm helmet soldier creature" /></div>
    </AutoplaySlider>
)


//
//  wall.
//
export default function Landing() {

    // return.
    return (
        <>
            <Link to={ "/gallery/"  }>
                {AutoplaySlider1}
            </Link>
        </>
    );

}
