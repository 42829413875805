//
//  src/components/wall/Wall.js
//
import React from "react";
import './Wall.css'


//
//  ink[s]
//
function importAll(r) { return r.keys().map(r); }
const Inks = importAll( require.context('../../images/gallery/', false, /\.(webp)$/) );


//
//  ink[s] image[s]
//
const InksImages = Inks.map((Ink) =>
    <InksImagesImage key={Ink} src={Ink} />
);


//
//  ink[s] image[s] image
//
function InksImagesImage(props) {
    let image_alt = props.src.split('.')[0].split('/')[3];
    //let image_size        = props.src.split('.')[1].split('/')[0];
    //let image_size_width  = image_size.split('x')[0];
    //let image_size_height = image_size.split('x')[1];
    return <>
        <img
            className="load"
            src={props.src}
            alt={image_alt}
            height="800"
            width="100"
            loading="lazy"
        />
    </>
}


//
//  wall
//
export default class Wall extends React.Component {
    render() {
        return (
            <>
                <section className="wall">
                    <div className="container">
                        {InksImages}
                    </div>
                </section>
            </>
        );
    }
}
