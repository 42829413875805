//
//  src/layouts/depict/Depict.js
//
import * as React        from "react";
import { Routes, Route } from "react-router-dom";
import Home              from "./home/Home";
import Gallery           from "./gallery/Gallery";
import Contact           from "./contact/Contact";
import Location          from "./location/Location";
import Works             from "./works/Works";
import "materialize-css/dist/css/materialize.min.css";


//
//  depict.
//
export default function Depict() {
    return (
        <>
            <Routes>
                <Route path="/"           element={ <Home     /> } />
                <Route path="/gallery/*"  element={ <Gallery  /> } />
                <Route path="/contact/*"  element={ <Contact  /> } />
                <Route path="/location/*" element={ <Location /> } />
                <Route path="/works/"     element={ <Works    /> } />
            </Routes>
        </>
    );
}
