//
//  src/components/landing/Landing.js
//
import React, { useState, useEffect } from "react";
import Slider0 from "../sliders/Slider0";
import Slider1 from "../sliders/Slider1";
import Slider2 from "../sliders/Slider2";
import './Landing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAt
} from '@fortawesome/free-solid-svg-icons'
import { 
    faFacebook,
    faInstagram
} from '@fortawesome/free-brands-svg-icons'


//
//  landing [social-icon]
//
const LandingSocialIcon = (props) => (
    <div className="social">
        <a href={props.url}>
            <FontAwesomeIcon className="fa" icon={ props.icon } />
        </a>
    </div>
)


//
//  landing [desktop]
//
const LandingDesktop = (props) => (
    <>
        <div className="landing desktop">
            <div className="flex-container">
                <div className="flex-item-left">
                    <div className="title">
                        <h4><b>We specialise in making your ideas come to life through realistic tattoos.</b></h4>
                    </div>
                    <div className="brief">
                        <div className="text">
                            <p>Our skilled artists pay attention to every detail, turning your dreams into vibrant masterpieces. Whether it's a loved one's gaze or a cherished memory, we're here to make your story permanent.</p>
                        </div>
                        <div className="link">
                            <a className="contact" href='/contact/'>
                                <b>Contact Us</b>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex-item-right">
                    <div className="slider-0">
                        <Slider0 />
                    </div>
                    <div className="bar">
                    </div>
                    <div className="text">
                        <h6><b>Realistic Ink, Timeless Art.</b></h6>
                    </div>                    
                </div>                    
            </div>
        </div>
    </>
)


//
//  landing [mobile]
//
const LandingMobile = (props) => (
    <>
        <div className="landing mobile">
            <div id="flex-container-top" className="flex-container">
                <div className="flex-item-left profile-picture slider2">
                    <Slider2 />
                </div>
                <div className="flex-item-right">
                    <div className="text-1 valign-wrapper">
                        <div className="center-align">
                            <h4>Realistic <b>Ink</b></h4>
                            <p>We specialise in making your ideas come to life through realistic tattoos. Our skilled artists pay attention to every detail, turning your dreams into vibrant masterpieces. Whether it's a loved one's gaze or a cherished memory, we're here to make your story permanent. <a className="contact" href='/contact/'>Contact us</a> today for a tattoo experience that's all about bringing your vision to reality.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="flex-container-bot" className="flex-container">
                <div className="flex-item-left profile-picture slider1">
                    <Slider1 />
                </div>
                <div className="flex-item-right white">
                    <div className="text-1 valign-wrapper">
                        <div className="center-align">
                            <br />
                            <h4><b>Connect</b> & <b>Share</b></h4>
                            <p>Follow us on social media for a glimpse into our mesmerizing creations. Discover unique and professionally-crafted ink designs that leave a lasting impression. Join our vibrant tattoo community and be inspired by the art of storytelling through ink.</p>
                            <div className="row socials">
                                <LandingSocialIcon icon={faAt}        url="/contact/"                                />
                                <LandingSocialIcon icon={faFacebook}  url="https://www.facebook.com/JeffTattooer/"   />
                                <LandingSocialIcon icon={faInstagram} url="https://www.instagram.com/jeff_tattooer/" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
)


//
//  landing [responsive]
//
export default function Landing() {

    // viewport[s].
    const [isDesktop, setDesktop] = useState( window.innerWidth  >  1000 );
    //const [isTablet,  setTablet]  = useState( window.innerWidth === 1000 );
    const [isTablet            ]  = useState( window.innerWidth === 1000 );
    const [isMobile,  setMobile]  = useState( window.innerWidth  <  1000 );

    // update container height.
    const updateContainerHeight = () => {
        let containerHeight = document.getElementById("flex-container-top");
        if( containerHeight !== null ){
            document.getElementById("flex-container-bot").style.height = containerHeight.clientHeight + "px"
        }
    }

    // update media viewport.
    const updateMedia = () => {
        setDesktop( window.innerWidth  >  1000 );
        setMobile(  window.innerWidth === 1000 );
        setMobile(  window.innerWidth  <  1000 );
        updateContainerHeight();
    };

    // use resize.
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        setTimeout(function() { updateContainerHeight() },  50)
        setTimeout(function() { updateContainerHeight() }, 100)
        setTimeout(function() { updateContainerHeight() }, 400)
        setTimeout(function() { updateContainerHeight() }, 800)
        return () => window.removeEventListener("resize", updateMedia);
    });

    // return
    return (
        <>
            { isDesktop && <LandingDesktop /> }
            { isTablet  && <LandingMobile  /> }
            { isMobile  && <LandingMobile  /> }
        </>
    );

}
