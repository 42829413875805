//
//  src/layouts/Index.js
//
import React  from "react";
import Depict from "./depict/Depict";
import './Index.css'


//
//  index.
//
export default function Index() {
    return ( <Depict /> );
}
