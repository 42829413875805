//
//  src/layouts/depict/location/Location.js
//
import * as React from "react";
import Nav    from "../../../components/nav/Nav"
import Map    from "../../../components/map/Map"
import Footer from "../../../components/footer/Footer"


//
//  location.
//
export default function Location() {
    return (
        <div id="location">
           <Nav    />
           <Map    />
           <Footer />
        </div>
    );
}
