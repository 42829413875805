//
//  src/components/enquire/Enquire.js
//
import * as React        from 'react';
import { Routes, Route } from 'react-router-dom';
import EnquireForm       from './EnquireForm';
import EnquireFormError  from './EnquireFormError';
import EnquireFormSent   from './EnquireFormSent';
import './Enquire.css'


//
//  enquire
//
export default function Enquire(props) {

     return (
          <div className="container enquire">
               <Routes>
                    <Route path=""       element={<EnquireForm      />} />
                    <Route path="error/" element={<EnquireFormError />} />
                    <Route path="sent/"  element={<EnquireFormSent  />} />
               </Routes>
          </div>
     );

}
