//
//  src/index.js
//
import * as React        from "react";
import ReactDOM          from "react-dom/client";
import { Helmet }        from 'react-helmet';
import { BrowserRouter } from "react-router-dom";
//import { Auth0Provider } from "@auth0/auth0-react";
import Index              from "./layouts/Index";


//
//  root.
//
const root = ReactDOM.createRoot(
    document.getElementById("root")
);
//root.render(
//    <Auth0Provider domain="dev-px3vg8w2.us.auth0.com" clientId="8O7vo22BAhzDW2Jw1mNjgUIj1LlB9eSP" redirectUri={window.location.origin}>
//        <BrowserRouter>
//            <App />
//        </BrowserRouter>
//    </Auth0Provider>
//);
root.render(
    <>
        <Helmet>
        </Helmet>
        <BrowserRouter>
            <Index />
        </BrowserRouter>
    </>
);
