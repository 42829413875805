//
//  src/components/map/Map.js
//
import React          from "react";
import GoogleMapReact from 'google-map-react';
import Logo           from "../../images/tmc.webp"
import './Map.css'


//
//  map marker.
//
const MapMarker = ({ title }) => <>
    <div className="map-marker">
        <a href="https://g.page/the_melbourne_compendium?share">
            <img className="animate" src={ Logo } alt={ title } />
        </a>        
    </div>
</>;


//
//  map.
//
export default function Map(){

    const defaultProps = {
        center: {
            lat: -37.856055,
            lng: 145.018361
        },
        zoom: 16
    };

    return (
        <div className="map">
            <GoogleMapReact
                bootstrapURLKeys={{ 
                    key       : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                    libraries : [ 'visualization' ] 
                }}
                defaultCenter = { defaultProps.center }
                defaultZoom   = { defaultProps.zoom   }
                options = {
                    {
                    styles: [
                        {
                            featureType : "poi.business",
                            stylers : [
                              { "visibility": "off" }
                            ]
                        },
                        { 
                            stylers: [
                                { 'saturation': -100 }
                            ] 
                        }
                    ]
                }}
            >
                <MapMarker
                    lat  = { -37.855055 }
                    lng  = { 145.018361 }
                    text = "The Melbourne Compendium"
                />
            </GoogleMapReact>
        </div>
    );

}
